import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import axios from "axios";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Typography } from "@mui/material";
import Dashboard from "./Dashboard";

const theme = createTheme({
  palette: {
    primary: {
      main: "#3f51b5",
    },
    secondary: {
      main: "#f50057",
    },
    background: {
      default: "#EEF2E6",
    },
    text: {
      primary: "#25316D",
    },
    success: {
      main: "#67bd6a",
    },
    info: {
      main: "#5d86a6",
    },
    warning: {
      main: "#FF7F3F",
    },
    error: {
      main: "#e6655c",
    },
  },
});

const columns = [
  {
    field: "repo_name",
    headerName: "Repository Name",
    width: 250,
    headerClassName: "table-header",
    headerAlign: "center",
    flex: 1,
  },
  {
    field: "pull_no",
    headerName: "Pull No",
    width: 70,
    headerClassName: "table-header",
    headerAlign: "center",
    flex: 1,
  },
  {
    field: "created_by",
    headerName: "Created By",
    width: 100,
    headerClassName: "table-header",
    headerAlign: "center",
    flex: 1,
  },
  {
    field: "pull_request_name",
    headerName: "Pull Request Name",
    width: 320,
    headerClassName: "table-header",
    headerAlign: "center",
    flex: 1,
  },
  {
    field: "age",
    headerName: "Age",
    type: "number",
    width: 80,
    headerClassName: "table-header",
    headerAlign: "center",
    flex: 1,
  },
  {
    field: "total_comments",
    headerName: "Total Comments",
    type: "number",
    width: 150,
    headerClassName: "table-header",
    headerAlign: "center",
    flex: 1,
  },
  {
    field: "commits",
    headerName: "Total Commits",
    type: "number",
    width: 150,
    headerClassName: "table-header",
    headerAlign: "center",
    flex: 1,
  },
  {
    field: "files_changed",
    headerName: "Files Changed",
    type: "number",
    width: 150,
    headerClassName: "table-header",
    headerAlign: "center",
    flex: 1,
  },
  {
    field: "state",
    headerName: "State",
    width: 150,
    headerClassName: "table-header",
    headerAlign: "center",
    flex: 1,
  },
];

function App() {
  const [pullRequest, setPullRequest] = useState([]);
  const [pageSize, setPageSize] = React.useState(20);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_GITHUB_INFO}`).then(function (response) {
      setPullRequest(response.data);
      console.log(response.data);
    });
  }, []);

  const sync = () => {
    axios.post(`${process.env.REACT_APP_GITHUB_SYNC}`);
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <Typography
          variant="h2"
          color="success.main"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <b>Dashboard Metrics</b>
        </Typography>

        <Button variant="contained" onClick={sync}>
          <b>Sync</b>
        </Button>

        <Dashboard pullRequest={pullRequest} columns={columns} />
      </ThemeProvider>
    </>
  );
}

export default App;
