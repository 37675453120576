import React from "react";

import Box from "@mui/material/Box";

import { DataGrid, GridToolbar } from "@mui/x-data-grid";

function Dashboard({ pullRequest, columns }) {
  const [pageSize, setPageSize] = React.useState(20);
  return (
    <>
      <Box
        sx={{
          height: 570,
          width: "100%",
          "& .table-header": {
            backgroundColor: "rgba(255, 7, 0, 0.55)",
          },

          border: 2,
          borderColor: "primary.light",
          "& .MuiDataGrid-cell:hover": {
            color: "primary.main",
          },
          mt: 2,
          mr: 5,
        }}
      >
        <DataGrid
          components={{ Toolbar: GridToolbar }}
          color="success"
          display="flex"
          justifyContent="center"
          alignItems="center"
          rows={pullRequest}
          columns={columns}
          getRowId={(row) => (row._id ? row._id : "12")}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[5, 10, 20]}
          pagination
          disableSelectionOnClick
        />
      </Box>
    </>
  );
}

export default Dashboard;
